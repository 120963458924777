<template>
    <div>
        <home-pc v-if="$responsive.is('pc')"></home-pc>
        <home-mobile v-if="$responsive.is('mobile')"></home-mobile>
    </div>
</template>


<script>
  import HomePC from './home-pc';
  import HomeMobile from './home-mobile';

  export default {

    components: {
      'home-pc': HomePC,
      'home-mobile': HomeMobile,
    },

  }
</script>